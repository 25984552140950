/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'VinaSans';
  src: url('/fonts/Vina_Sans/VinaSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'CupCakes';
  src: url('/fonts/CupCakes.otf') format('opentype');
}

@font-face {
  font-family: 'MoreSugar';
  src: url('/fonts/more-sugar/MoreSugar-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway/static/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('/fonts/Raleway/static/Raleway-Bold.ttf') format('truetype');
}

body {
  @apply font-raleway bg-gutup-white text-sm md:text-base cursor-custom;

  a, button {
    @apply cursor-pointer;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #FFB69B transparent;
}

.gutup-container {
  @apply mx-[2vh];
}

.gutup-button {
  @apply text-base md:text-lg font-moresugar border border-black bg-white shadow-md text-black text-center rounded-xl py-2 px-4 transition-transform;
  box-shadow: 0 3px 0 0 black;
  transition: transform .3s cubic-bezier(.6, -.28, .735, .045), box-shadow .3s ease-in-out;
}

.gutup-button:hover {
  box-shadow: 0 6px 0 0 black;
  transform: translate(0, -2px);
}

.gutup-title {
  @apply text-4xl md:text-5xl font-vinasans;
}

.gutup-header-button {
  @apply font-vinasans text-4xl hover:text-gutup-hover transition-colors duration-300 ease-in-out cursor-pointer;
}

.gutup-header-button-no-hover {
  @apply font-vinasans text-4xl
}

.gutup-footer-button {
  @apply font-vinasans text-3xl hover:text-gutup-hover transition-colors duration-300 ease-in-out cursor-pointer;
}

.gutup-footer-small-button {
  @apply font-vinasans text-xl hover:text-gutup-hover transition-colors duration-300 ease-in-out cursor-pointer;
}

.gutup-cupcakes-subtitle {
  @apply font-cupcakes text-2xl md:text-3xl;
}
